#OurServices {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}

#OurServices h1 {
  color: #555;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#OurServices > h4 {
  color: #687eff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.8px;
}

.div-ourServices {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 30px 120px;
  column-gap: 30px;
}

.loop1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  row-gap: 8px;
  height: 160px;
  width: 284px;
  border-radius: 5px;
  border: 1px solid #dedede;
  background: #fff;
}

.loop1 div {
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.loop1 h4 {
  margin-top: 25px;
  color: #687eff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.loop1 p {
  color: #292929;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
}

.loop2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: 160px;
  width: 284px;
  row-gap: 8px;
  border-radius: 5px;
  background: #687eff;
  box-shadow: 0px 4px 20px 0px rgba(12, 82, 137, 0.2);
}

.loop2 div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid #687eff;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.loop2 h4 {
  margin-top: 25px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.loop2 p {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
}


@media screen and (max-width: 885px) {
  #OurServices {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  #OurServices h1 {
    font-size: 22px;
    font-weight: 700;
  }
  
  #OurServices > h4 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1.8px;
  }
  
  .div-ourServices {
    margin: 10px 600px;
    margin-top: 30px;
    column-gap: 10px;
    row-gap: 38px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .loop1 {
    row-gap: 8px;
    height: 110px;
    width: 204px;
    border-radius: 5px;
  }
  
  .loop1 div {
    width: 60px;
    height: 60px;
    top: -35px;
  }
  
  .loop1 h4 {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .loop1 p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 0 15px;
  }
  
  .loop2 {
    height: 110px;
    width: 204px;
    row-gap: 8px;
    border-radius: 5px;
  }
  
  .loop2 div {
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: absolute;
    top: -35px;
  }
  
  .loop2 h4 {
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  
  .loop2 p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 0 15px;
  }
  .serviceImg{
    height: 29.5px;
  }
}

@media screen and (max-width: 576px) {
  #OurServices {
    margin-top: 10px;
  }
  
  #OurServices h1 {
    font-size: 12px;
    font-weight: 700;
  }
  
  #OurServices > h4 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.8px;
  }
  
  .div-ourServices {
    margin: 15px 30px;
    column-gap: 15px;
    row-gap: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .loop1 {
    row-gap: 2px;
    height: 80px;
    width: 140px;
    border-radius: 5px;
  }
  
  .loop1 div {
    width: 40px;
    height: 40px;
    top: -20px;
  }
  
  .loop1 h4 {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 600;
  }
  
  .loop1 p {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    padding: 0 15px;
  }
  
  .loop2 {
    height: 80px;
    width: 140px;
    row-gap: 2px;
    border-radius: 5px;
  }
  
  .loop2 div {
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
  }
  
  .loop2 h4 {
    margin-top: 10px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }
  
  .loop2 p {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    padding: 0 15px;
  }

  .serviceImg{
    height: 17.5px;
  }
}

@media screen and (min-width: 885px) {
  #OurServices {
    margin-top: 10px;
  }
  
  #OurServices h1 {
    font-size: 22px;
    font-weight: 700;
  }
  
  #OurServices > h4 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1.8px;
  }
  
  .div-ourServices {
    margin: 30px 120px;
    column-gap: 30px;
    row-gap: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .loop1 {
    row-gap: 8px;
    height: 150px;
    width: 184px;
    border-radius: 5px;
  }
  
  .loop1 div {
    width: 60px;
    height: 60px;
    top: -35px;
  }
  
  .loop1 h4 {
    margin-top: 18px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop1 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 15px;
  }
  
  .loop2 {
    height: 150px;
    width: 184px;
    row-gap: 8px;
    border-radius: 5px;
  }
  
  .loop2 div {
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: absolute;
    top: -35px;
  }
  
  .loop2 h4 {
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop2 p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 15px;
  }
  .serviceImg{
    height: 29.5px;
  }
}

@media screen and (min-width: 980px) {
  #OurServices {
    margin-top: 10px;
  }
  
  #OurServices h1 {
    font-size: 22px;
    font-weight: 700;
  }
  
  #OurServices > h4 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1.8px;
  }
  
  .div-ourServices {
    margin: 30px 120px;
    column-gap: 30px;
    row-gap: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .loop1 {
    row-gap: 8px;
    height: 150px;
    width: 220px;
    border-radius: 5px;
  }
  
  .loop1 div {
    width: 60px;
    height: 60px;
    top: -35px;
  }
  
  .loop1 h4 {
    margin-top: 18px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop1 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 15px;
  }
  
  .loop2 {
    height: 150px;
    width: 220px;
    row-gap: 8px;
    border-radius: 5px;
  }
  
  .loop2 div {
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: absolute;
    top: -35px;
  }
  
  .loop2 h4 {
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop2 p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 15px;
  }
  .serviceImg{
    height: 29.5px;
  }
}

@media screen and (min-width:1270px){
  #OurServices {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  #OurServices h1 {
    color: #555;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  #OurServices > h4 {
    color: #687eff;
    font-size: 30px;
    margin-bottom: 5px;
    font-weight: 700;
    letter-spacing: 1.8px;
  }
  
  .div-ourServices {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 120px;
    column-gap: 30px;
  }
  
  .loop1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    row-gap: 8px;
    height: 160px;
    width: 284px;
    border-radius: 5px;
    border: 1px solid #dedede;
    background: #fff;
  }
  
  .loop1 div {
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  
  .loop1 h4 {
    margin-top: 25px;
    color: #687eff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop1 p {
    color: #292929;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
  }
  
  .loop2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 160px;
    width: 284px;
    row-gap: 8px;
    border-radius: 5px;
    background: #687eff;
    box-shadow: 0px 4px 20px 0px rgba(12, 82, 137, 0.2);
  }
  
  .loop2 div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border: 1px solid #687eff;
    background: #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  
  .loop2 h4 {
    margin-top: 25px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  
  .loop2 p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
  }
  
  .serviceImg{
    height: 31px;
  }
}