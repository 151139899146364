#AboutUs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  column-gap: 40px;
}

#AboutUs p,
h4,
h2,
h3 {
  margin: 0;
}

.container-about {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.aboutus-h4 {
  color: #687eff;
  font-size: 28.174px;
  font-weight: 500;
  letter-spacing: 2.817px;
  text-transform: uppercase;
}

.cepatdanmudah {
  color: #555;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.cepatdanmudah span {
  color: #687eff;
}

.p-aboutus {
  color: #292929;
  text-align: justify;
  font-family: "Work Sans";
  font-size: 16px;
  line-height: 24px;
}

.mesincuci2 {
  width: 420px;
}

/* Responsive Design */
@media screen and (max-width: 1270px) {
  #AboutUs {
    padding: 40px;
    column-gap: 15px;
  }

  .aboutus-h4 {
    font-size: 18px;
  }

  .cepatdanmudah {
    font-size: 30px;
  }

  .p-aboutus {
    font-size: 16px;
    line-height: 16px;
  }

  .mesincuci2 {
    width: 350px;
  }
}

@media screen and (max-width: 920px) {
  #AboutUs {
    padding: 30px;
  }

  .aboutus-h4 {
    font-size: 14px;
  }

  .cepatdanmudah {
    font-size: 24px;
  }

  .p-aboutus {
    font-size: 14px;
    line-height: 14px;
  }

  .mesincuci2 {
    width: 350px;
  }
}

@media screen and (max-width: 769px) {
  #AboutUs {
    padding: 20px;
  }

  .aboutus-h4 {
    font-size: 10px;
    letter-spacing: 2px;
  }

  .cepatdanmudah {
    font-size: 20px;
  }

  .p-aboutus {
    font-size: 12px;
    line-height: 12px;
  }

  .mesincuci2 {
    width: 250px;
  }
}

@media screen and (max-width: 576px) {
  #AboutUs {
    padding: 20px;
  }

  .aboutus-h4 {
    font-size: 10px;
    letter-spacing: 2px;
  }

  .cepatdanmudah {
    font-size: 13px;
  }

  .p-aboutus {
    font-size: 8px;
    line-height: 10px;
  }

  .mesincuci2 {
    width: 150px;
  }
}
