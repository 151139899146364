#Header {
  padding: 35px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 99999;
}

button {
  border: none;
  background-color: #f5f5f5;
  color: #f5f5f5;
}

.navbarli {
  background: none;
}

.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 200px;
}

.nav-img {
  width: 250px;
}

a {
  text-decoration: none;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 2vh;
}

#navbar li {
  list-style: none;
  row-gap: 2em;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  color: var(--Dark-Gray, #555);
  text-transform: uppercase;
  font-weight: 500;
}

.navbardiv a {
  color: #ffff;
}

.navbardiv1 a {
  color: #000;
}

.nav-Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 30px;
}

.menu {
  display: none;
  position: absolute;
  top: 2.25rem;
  right: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

.menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #687eff;
  border-radius: 0.2rem;
}

.signin {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  border-radius: 50px;
  background: #06d001;
  color: #ffff;
  padding: 15px 35px;
  margin: 0 15px;
  font-weight: 600;
}

.IoSearch {
  width: 30px;
  height: 30px;
}

.signup {
  color: #292929;
  background: #dedede;
  padding: 15px 35px;
  border-radius: 50px;
  margin: 0 15px;
  font-weight: 600;
}

.divCloseBtn {
  display: none;
}

.imgHome {
  display: none;
}

@media screen and (max-width: 769px) {
  #Header {
    padding: 15px 25px;
    z-index: 99999;
  }

  .nav-img {
    width: 140px;
  }

  .menu {
    display: flex;
    top: 15px;
    right: 25px;
    width: 35px;
    height: 30px;
  }

  .menu span {
    height: 0.3rem;
    width: 90%;
    background-color: #687eff;
    border-radius: 0.2rem;
  }

  #navbar {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-left: 1px solid #ddd;
    transform: translateX(0);
    animation: slideIn 0.3s ease;
    -webkit-animation: slideIn 0.3s ease;
  }

  #navbar.open {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    row-gap: 27px;
    padding: 0;
    margin-top: 2px;
    transform: translateX(0);
    animation: slideIn 0.3s forwards;
  }

  #navbar.open > li {
    text-align: left;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .nav-Login {
    width: 100%;
  }

  #navbar li {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
  }

  #navbar li a {
    margin: 0.2rem 0.5rem;
    text-align: center;
    font-size: 14px;
  }

  .liHome {
    padding-top: 20px;
  }

  .signin {
    border-radius: 10px;
    font-size: 12px;
    padding: 7px 18px;
    font-weight: 600;
  }

  .IoSearch {
    width: 15px;
    height: 15px;
  }

  .signup {
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 50px;
    font-weight: 600;
  }

  .divCloseBtn {
    display: flex;
    align-items: center;
  }

  .logoAkucuciin2 {
    padding: 5px;
    width: 40px;
    height: 30px;
  }

  .imgHome {
    display: flex;
    width: 20px;
    height: 20px;
  }

  .close-btn {
    border: none;
    background-color: white;
    width: 40px;
    height: 30px;
    position: absolute;
    top: 5px;
    padding-top: 3px;
  }

  .navLine {
    background-color: #687eff;
    height: 2px;
    width: 180px;
    align-self: center;
    position: absolute;
    top: 35px;
  }

  .navbardiv {
    align-self: center;
  }
}

@media screen and (max-width: 576px) {
  .closeX {
    color: #687eff;
  }
  #Header {
    padding: 15px 25px;
    z-index: 99999;
  }

  .nav-img {
    width: 120px;
  }

  .menu {
    display: flex;
    top: 18px;
    right: 20px;
    width: 27px;
    height: 20px;
  }

  .menu span {
    height: 0.2rem;
    width: 90%;
    background-color: #687eff;
    border-radius: 0.2rem;
  }

  #navbar {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 170px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-left: 1px solid #ddd;
    transform: translateX(0);
    animation: slideIn 0.3s ease;
  }

  #navbar.open {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    row-gap: 23px;
    padding: 0;
    margin-top: 2px;
    transform: translateX(0);
    animation: slideIn 0.3s forwards;
  }

  #navbar.open > li {
    text-align: left;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .nav-Login {
    width: 100%;
  }

  #navbar li {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
  }

  #navbar li a {
    margin: 0.2rem 0.5rem;
    text-align: center;
    font-size: 14px;
  }

  .signin {
    border-radius: 50px;
    font-size: 12px;
    padding: 5px 15px;
    font-weight: 600;
  }

  .IoSearch {
    width: 10px;
    height: 10px;
  }

  .signup {
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 50px;
    font-weight: 600;
  }

  .divCloseBtn {
    display: flex;
    align-items: center;
  }

  .logoAkucuciin2 {
    padding: 5px;
    width: 40px;
    height: 30px;
  }

  .imgHome {
    display: flex;
    width: 20px;
    height: 20px;
  }

  .close-btn {
    border: none;
    background-color: white;
    width: 25px;
    height: 30px;
    position: absolute;
    right: 0;
    padding-top: 3px;
  }

  .navLine {
    background-color: #687eff;
    height: 2px;
    width: 150px;
    align-self: center;
    position: absolute;
    top: 35px;
  }

  .signin {
    border-radius: 10px;
    font-size: 10px;
    padding: 6px 13px;
    font-weight: 600;
  }

  .signup {
    font-size: 10px;
    padding: 4px 12px;
    border-radius: 50px;
    font-weight: 600;
  }
}

@media screen and (min-width: 769px) {
  .nav-img {
    transition: transform 0.4s ease-in-out, filter 0.3s ease-in-out;
    filter: brightness(1);
  }

  .nav-img:hover {
    filter: brightness(1.3);
  }

  .navbarli {
    position: relative;
    overflow: hidden;
    color: #000;
    background: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.4s ease-in-out;
    -webkit-transition: color 0.4s ease-in-out;
    -moz-transition: color 0.4s ease-in-out;
    -ms-transition: color 0.4s ease-in-out;
    -o-transition: color 0.4s ease-in-out;
}

  .navbarli:hover {
    color: #687eff;
  }

  .navbarli::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #007bff, #00c6ff);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
  }

  .navbarli:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }

  .menu {
    position: relative;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
  }

  .menu span {
    display: block;
    width: 25px;
    height: 3px;
    background: #000;
    margin: 5px auto;
    border-radius: 2px;
    transition: all 0.4s ease-in-out;
  }

  .menu:hover span:nth-child(1) {
    transform: translateX(5px);
  }

  .menu:hover span:nth-child(2) {
    transform: translateX(-5px);
  }

  .menu:hover span:nth-child(3) {
    transform: translateX(5px);
  }


  @keyframes fadeSlideIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes logoBounceIn {
    0% {
      opacity: 0;
      transform: scale(0.8) translateY(-20px);
    }
    60% {
      transform: scale(1.1) translateY(10px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  @keyframes menuSlideIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .nav-img {
    animation: logoBounceIn 0.8s ease-in-out;
  }

  #navbar {
    animation: fadeSlideIn 0.8s ease-in-out;
  }

  .menu {
    animation: menuSlideIn 0.8s ease-in-out 0.4s;
  }

  .navbarli {
    opacity: 0;
    transform: translateY(10px);
    animation: fadeSlideIn 0.5s ease-in-out forwards;
  }

  .navbarli:nth-child(1) {
    animation-delay: 0.2s;
  }
  .navbarli:nth-child(2) {
    animation-delay: 0.4s;
  }
  .navbarli:nth-child(3) {
    animation-delay: 0.6s;
  }
  .navbarli:nth-child(4) {
    animation-delay: 0.8s;
  }

  .navbarli button {
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .navbarli button::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #3498db;
    transition: width 0.4s ease-in-out;
  }

  .navbarli button:hover::before {
    width: 100%;
  }

  .navbarli button:hover {
    color: #3498db;
    transform: translateY(-3px);
  }

  #Header {
    padding: 25px 30px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 150px;
  }

  .nav-img {
    width: 190px;
  }

  #navbar {
    row-gap: 1vh;
  }

  #navbar li {
    row-gap: 1em;
    padding: 0 10px;
  }

  #navbar li a {
    text-decoration: none;
    color: var(--Dark-Gray, #555);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
  }

  .navbardiv a {
    color: #ffff;
  }

  .navbardiv1 a {
    color: #000;
  }

  .nav-Login {
    column-gap: 20px;
  }

  .signin {
    border-radius: 50px;
    font-size: 14px;
    padding: 7px 18px;
    font-weight: 600;
  }

  .IoSearch {
    width: 20px;
    height: 20px;
  }

  .signup {
    font-size: 14px;
    padding: 7px 18px;
    border-radius: 50px;
    font-weight: 600;
  }
}

@media screen and (min-width: 920px) {
  #Header {
    padding: 30px 35px;
  }

  .container {
    column-gap: 170px;
  }

  .nav-img {
    width: 220px;
  }

  #navbar {
    row-gap: 1vh;
  }

  #navbar li {
    row-gap: 1em;
    padding: 0 10px;
  }

  #navbar li a {
    text-decoration: none;
    color: var(--Dark-Gray, #555);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
  }

  .nav-Login {
    column-gap: 20px;
  }

  .signin {
    border-radius: 50px;
    font-size: 20px;
    padding: 9px 18px;
    font-weight: 600;
  }

  .IoSearch {
    width: 25px;
    height: 25px;
  }

  .signup {
    font-size: 20px;
    padding: 9px 18px;
    border-radius: 50px;
    font-weight: 600;
  }
}

@media screen and (min-width: 1230px) {
  #Header {
    padding: 35px 45px;
  }

  .container {
    column-gap: 200px;
  }

  .nav-img {
    width: 250px;
  }

  #navbar {
    row-gap: 2vh;
  }

  #navbar li {
    row-gap: 2em;
    padding: 0 20px;
  }

  #navbar li a {
    text-decoration: none;
    color: var(--Dark-Gray, #555);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 21px;
  }

  .nav-Login {
    column-gap: 30px;
  }

  .signin {
    border-radius: 50px;
    color: #ffff;
    padding: 15px 35px;
    font-weight: 600;
  }

  .IoSearch {
    width: 30px;
    height: 30px;
  }

  .signup {
    color: #292929;
    background: #dedede;
    padding: 15px 35px;
    border-radius: 50px;
    font-weight: 600;
  }
}
