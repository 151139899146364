

@media screen and (max-width: 769px) {
  #OurServicesHP {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #OurServicesHP h1 {
    color: #555;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  #OurServicesHP > h4 {
    color: #687eff;
    font-size: 20px;
    margin-bottom: 4px;
    font-weight: 700;
    letter-spacing: 1.8px;
  }

  .div-ourServicesHP {
    margin: 15px 30px;
    column-gap: 20px;
    row-gap: 32px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .loop1HP {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    row-gap: 2px;
    height: 110px;
    width: 160px;
    border-radius: 5px;
    border: 1px solid #dedede;
    background: #fff;
  }

  .loop1HP div {
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    top: -20px;
    position: absolute;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .loop1HP h4 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #687eff;
    text-align: center;
    
  }

  .loop1HP p {
    color: #292929;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    padding: 0 15px;
  }

  .loop2HP {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 110px;
    width: 160px;
    row-gap: 2px;
    border-radius: 5px;
    background: #687eff;
    box-shadow: 0px 4px 20px 0px rgba(12, 82, 137, 0.2);
  }

  .loop2HP div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border: 1px solid #687eff;
    background: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    position: absolute;
    top: -20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .loop2HP h4 {
    margin-top: 12px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }

  .loop2HP p {
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    padding: 0 15px;
  }

  .serviceImgHP{
    height: 17.5px;
  }
}
