html,
body {
  margin: 0;
  padding: 0;
}

.btn-confirm {
  background-color: #3085d6;
  color: white;
}

.btn-cancel {
  background-color: #aaa;
  color: black;
}

#SectionHome {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
}
.container-home {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.container-home p,
h4,
h2,
h3 {
  margin: 0;
}

.highly {
  color: var(--Black-Color, #292929);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gasempet {
  color: var(--Dark-Gray, #555);
  font-family: "Work Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}

.gasempet span {
  color: var(--Akucuciin, #687eff);
  font-family: "Work Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}

.platform {
  color: var(--Black-Color, #292929);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.trusted {
  color: var(--Primery-Color-2, #1195ff);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  margin-top: 50px;
}

.section-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  column-gap: 20px;
}

.high {
  color: var(--Dark-Gray, #555);
  font-family: "Work Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.mesincuci {
  width: 70em;
}

.divkosong {
  width: 100%;
  height: 100px;
  margin: 0;
  flex-shrink: 0;
  background-color: #687eff;
}

@media screen and (max-width: 769px) {
  #SectionHome {
    padding-left: 25px;
  }

  .container-home {
    row-gap: 5px;
  }

  .highly {
    font-size: 10px;
    font-weight: 400;
  }

  .gasempet {
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
  }

  .gasempet span {
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
  }

  .platform {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: justify;
  }

  .trusted {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin-top: 10px;
  }

  .section-login {
    margin-top: 10px;
    column-gap: 10px;
  }

  .high {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .mesincuci {
    width: 300px;
  }

  .divkosong {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 576px) {
  #SectionHome {
    padding-left: 20px;
  }

  .container-home {
    row-gap: 5px;
  }

  .highly {
    font-size: 8px;
    font-weight: 400;
  }

  .gasempet {
    font-size: 17px;
    font-weight: 700;
    line-height: 18px;
  }

  .gasempet span {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }

  .platform {
    display: none;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: justify;
  }

  .trusted {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin-top: 4px;
  }

  .section-login {
    margin-top: 4px;
    column-gap: 10px;
  }

  .high {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .mesincuci {
    width: 200px;
  }

  .divkosong {
    width: 100%;
    height: 30px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 769px) {
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  #SectionHome {
    animation: fadeIn 0.6s ease-out forwards;
  }

  .container-home {
    animation: fadeIn 0.8s ease-out forwards;
  }

  .font-work {
    animation: fadeIn 1s ease-out forwards;
  }

  .highly,
  .gasempet,
  .platform {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeIn 0.8s ease-out forwards;
  }

  .signin {
    position: relative;
    animation: fadeIn 0.8s ease-out forwards;
    transition: transform 0.3s ease;
  }

  .signin:hover {
    transform: scale(1.05);
  }

  .trusted,
  .high {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeIn 1.1s ease-out forwards;
  }

  .mesincuci {
    opacity: 0;
    transform: scale(0.8);
    animation: fadeIn 1s ease-out forwards;
    transition: transform 0.3s ease, filter 0.3s ease;
  }

  .mesincuci:hover {
    transform: scale(1.5);
    filter: brightness(1.1);
}

  .section-login button {
    cursor: pointer;
  }

  #SectionHome {
    padding-left: 35px;
  }

  .container-home {
    row-gap: 7px;
  }

  .highly {
    font-size: 14px;
    font-weight: 400;
  }

  .gasempet {
    font-size: 28px;
    font-weight: 700;
    line-height: 27px;
  }

  .gasempet span {
    font-size: 28px;
    font-weight: 700;
    line-height: 27px;
  }

  .platform {
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    text-align: justify;
  }

  .trusted {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin-top: 10px;
  }

  .section-login {
    margin-top: 10px;
    column-gap: 10px;
  }

  .high {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .mesincuci {
    width: 350px;
  }
  .divkosong {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 920px) {
  #SectionHome {
    padding-left: 50px;
  }

  .container-home {
    row-gap: 10px;
  }

  .highly {
    font-size: 18px;
    font-weight: 400;
  }

  .gasempet {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
  }

  .gasempet span {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
  }

  .platform {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
  }

  .trusted {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin-top: 10px;
  }

  .section-login {
    margin-top: 10px;
    column-gap: 10px;
  }

  .high {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .mesincuci {
    width: 500px;
  }

  .divkosong {
    width: 100%;
    height: 70px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 1270px) {
  #SectionHome {
    padding-left: 100px;
  }
  .container-home {
    row-gap: 15px;
  }

  .highly {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }

  .gasempet {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
  }

  .gasempet span {
    font-size: 50px;
    line-height: 50px;
  }

  .platform {
    font-size: 18px;
    line-height: 24px;
  }

  .trusted {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    margin-top: 20px;
  }

  .section-login {
    margin-top: 20px;
    column-gap: 20px;
  }

  .high {
    color: var(--Dark-Gray, #555);
    font-family: "Work Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .mesincuci {
    width: 40em;
  }

  .divkosong {
    height: 100px;
  }
}
